<template>
    <v-col class="mt-6 pt-6">
        <v-overlay :value="overlay" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-row>
            <v-col sm="12">
                <v-row>
                    <v-col md="12" class="py-0">
                        <v-alert
                            color="cyan"
                            border="left"
                            elevation="2"
                            colored-border
                            >
                                <template v-slot:prepend>
                                    <v-icon>mdi-cellphone-android</v-icon> TELÉFONOS DE CONTACTOS
                                </template>
                                <div class="float-right">
                                    <v-btn
                                        color="primary"
                                        small
                                        @click="storeContacts('phone')"
                                    >
                                        <v-icon>mdi-content-save</v-icon> GUARDAR
                                    </v-btn>
                                    <v-btn
                                        color="warning"
                                        small
                                        class="ml-3"
                                        @click="openReference('contactos-web.png')"
                                    >
                                        <v-icon>mdi-file-image</v-icon> REFERNCIA
                                    </v-btn>
                                </div>
                        </v-alert>
                    </v-col>
                    <v-col class="py-0">
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="phone"
                                    label="Teléfono fijo"
                                    prepend-icon="mdi-deskphone"
                                    type="text"
                                    v-mask="'##-#######'"
                                />
                            </v-col>
                            <v-col>
                                <v-text-field
                                    v-model="whatsapp"
                                    label="Teléfono móvil"
                                    v-mask="'593-###-#######'"
                                    prepend-icon="mdi-cellphone-iphone"
                                    type="text"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col md="12" class="pb-0">
                        <v-alert
                            color="cyan"
                            border="left"
                            elevation="2"
                            colored-border
                            icon="mdi-facebook"
                            >
                                <template v-slot:prepend>
                                    <v-icon>mdi-facebook</v-icon> REDES SOCIALES
                                </template>
                                <div class="float-right">
                                    <v-btn
                                        color="primary"
                                        small
                                        @click="storeContacts('redes')"
                                    >
                                        <v-icon>mdi-content-save</v-icon> GUARDAR
                                    </v-btn>
                                    <v-btn
                                        color="warning"
                                        small
                                        class="ml-3"
                                        @click="openReference('redes.png')"
                                    >
                                        <v-icon>mdi-file-image</v-icon> REFERNCIA
                                    </v-btn>
                                </div>
                            </v-alert>
                    </v-col>
                    <v-col class="py-0">
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="facebook"
                                    label="Facebook"
                                    prepend-icon="mdi-facebook"
                                    type="text"
                                />
                            </v-col>
                            <v-col>
                                <v-text-field
                                    v-model="instagram"
                                    label="Instagram"
                                    prepend-icon="mdi-instagram"
                                    type="text"
                                   
                                />
                            </v-col>
                            <v-col>
                                <v-text-field
                                    v-model="twitter"
                                    label="Twitter"
                                    prepend-icon="mdi-twitter"
                                    type="text"
                                   
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col md="12" class="pb-0">
                        <v-alert
                            color="cyan"
                            border="left"
                            elevation="2"
                            colored-border
                        >
                            <template v-slot:prepend>
                                <v-icon>mdi-whatsapp</v-icon> CHAT WHATSAPP
                            </template>
                            <div class="float-right">
                                <v-btn
                                    color="primary"
                                    small
                                    @click="storeContacts('whastapp')"
                                >
                                   <v-icon>mdi-content-save</v-icon> GUARDAR
                                </v-btn>
                                <v-btn
                                    color="warning"
                                    small
                                    class="ml-3"
                                    @click="openReference('whastapp.png')"
                                >
                                    <v-icon>mdi-file-image</v-icon> REFERNCIA
                                </v-btn>
                            </div>
                        </v-alert>
                    </v-col>
                    <v-col class="py-0">
                        <v-row>
                            <v-col md="4">
                                <v-text-field
                                    v-model="telWhatsapp"
                                    label="Teléfono de Whatsapp"
                                    prepend-icon="mdi-whatsapp"
                                    type="text"
                                    v-mask="'593-###-#######'"
                                />
                            </v-col>
                            <v-col md="8">
                                <v-text-field
                                    v-model="msgWhatsapp"
                                    label="Mensaje"
                                    prepend-icon="mdi-comment-text-outline"
                                    type="text"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog" persistent v-if="imageDialog!=null">
            <v-img :src="require('@/assets/images/'+imageDialog)" />
            <v-btn block @click="dialog=false; imageDialog=null">CERRAR</v-btn>
        </v-dialog>
    </v-col>
</template>

<script>

    import {mapState, mapMutations, mapActions} from 'vuex'

    export default {

        data: ()=>({
            overlay:false,
            phone:null,
            whatsapp:null,
            facebook:null,
            instagram:null,
            twitter:null,
            telWhatsapp:null,
            msgWhatsapp:null,
            dialog:false,
            imageDialog:null,
            phoneId:null,
            whatsappId:null,
            facebookId:null,
            instagramId:null,
            twitterId:null,
            telWhatsappId:null,
            msgWhatsappId:null,
        }),
        methods: {

            ...mapActions('master',['errorRequest','requestApi','alertNotification']),

            ...mapMutations('master',['setUrl','setTitleToolbar']),
            
            openReference(img){

                this.dialog=true
                this.imageDialog=img

            },

            storeContacts(typeAction){
                console.log(this.telWhatsappId,this.msgWhatsappId)
                //return false
                this.overlay=true
                this.setUrl('administracion')
                this.requestApi({
                    method: 'POST',
                    data :{
                        typeAction,
                        phone: this.phone,
                        whatsapp: this.whatsapp,
                        facebook: this.facebook,
                        instagram: this.instagram,
                        twitter: this.twitter,
                        telWhatsapp: this.telWhatsapp,
                        msgWhatsapp: this.msgWhatsapp,
                        phoneId: this.phoneId,
                        whatsappId: this.whatsappId,
                        facebookId: this.facebookId,
                        instagramId: this.instagramId,
                        twitterId: this.twitterId,
                        telWhatsappId: this.telWhatsappId,
                        msgWhatsappId: this.msgWhatsappId,
                    }
                }).then(res =>{

                    console.log(res)
                    this.overlay=false
                    this.alertNotification({
                        param:{
                            html: res.data.res.msg
                        }
                    })

                }).catch(()=>{
                    
                    const {status} = err.response
                    this.overlay=false

                    if(status === 500 || status === 422){
                        let response = err.response;
                        this.errorRequest({
                            data : {
                                datos: response.data.errors,
                                status : status,
                                response: response
                            }
                        })
                    }

                })

            },

            getDataWeb(){              
         
                this.setUrl('administracion')
                this.requestApi({
                    method: 'GET',
                    data :{
                        typeList: 'dataWeb',
                    }
                }).then(res =>{

                    for (const item of res.data._embedded.administracion) {

                        if(item.content_type_id == 'TLF_FIJO'){

                            this.phone= item.content_name
                            this.phoneId = item.content_id

                        }else if(item.content_type_id == 'TLF_MOVIL'){

                            this.whatsapp= item.content_name
                            this.whatsappId = item.content_id

                        }else if(item.content_type_id == 'FACEBOOK'){

                            this.facebook= item.content_name
                            this.facebookId = item.content_id

                        }else if(item.content_type_id == 'INSTAGRAM'){

                            this.instagram= item.content_name
                            this.instagramId = item.content_id

                        }else if(item.content_type_id == 'TWITTER'){

                            this.twitter= item.content_name
                            this.twitterId = item.content_id

                        }else if(item.content_type_id == 'TLF_WATHSAPP'){

                            this.telWhatsapp= item.content_name
                            this.telWhatsappId= item.content_id

                        }else if(item.content_type_id == 'MENSAJE_WATHSAPP'){

                            this.msgWhatsapp= item.content_name
                            this.msgWhatsappId = item.content_id

                        }
                        
                    }

                    console.log(this.msgWhatsappId,this.telWhatsappId)
                 
                }).then(()=>{
                    
                })

            },

        },
        created(){
            this.setTitleToolbar('CONTACTOS WEB')
            this.getDataWeb()
        }

    }

</script>